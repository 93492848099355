import { Component, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import API from "@api";
import { ApiError, ApiErrorResponse } from "@/types/api";
import { getUserProfileInfo } from "@/lib/auth";
import { maskEmail } from "@helpers";
import { authModule } from "@store/namespaces";
import { AuthGetters } from "@store/modules/auth/types";

interface ResendingForAllTime {
  expiresAt: string;
  count: number;
}

const maxCountResendPerDay = 5;
const maxCountResendPerWeek = 10;
const emailResendedCountdown = 10;

const resendingsForAllTimeStorageKey = (email: string) =>
  `(${email}):resendings-for-all-time`;

@Component
export default class ConfirmEmail extends Vue {
  @authModule.Getter("authenticated")
  private readonly authenticatedGetter!: AuthGetters["authenticated"];

  private userEmail = "";
  private loading = true;
  private emailResendedCountdown = {
    value: 0,
    intervalId: 0,
  };
  private emailResended = false;
  private emailResending = false;
  private resendingsForAllTime: ResendingForAllTime[] = [];

  private get disabledResendEmail() {
    if (this.emailResended) {
      return true;
    }

    if (
      !!this.resendingsPerDay?.count &&
      this.resendingsPerDay.count >= maxCountResendPerDay
    ) {
      return true;
    }

    const countResendingsForAllTime = this.resendingsForAllTime.reduce(
      (sum, { count }) => {
        return sum + count;
      },
      0
    );

    return countResendingsForAllTime >= maxCountResendPerWeek;
  }

  private get resendingsPerDay() {
    return this.resendingsForAllTime.find(({ expiresAt }) => {
      const expiresAtDate = new Date(expiresAt);

      return expiresAtDate.getTime() > Date.now();
    });
  }

  private get secretCode() {
    const { secret } = this.$route.query;

    return typeof secret === "string" ? secret : null;
  }

  private get maskedUserEmail() {
    return maskEmail(this.userEmail);
  }

  private get emailResendedCountdownProgress() {
    return Math.min(
      Math.ceil(
        (this.emailResendedCountdown.value / emailResendedCountdown) * 100
      ),
      100
    );
  }

  private onClickButton() {
    window.location.href = "/";
  }

  private autoRedirect(params: { authenticated?: boolean } = {}) {
    const { authenticated = this.authenticatedGetter } = params;

    const redirectUrl = this.$router.resolve({
      name: authenticated ? "verify" : "login",
    }).href;

    window.location.href = redirectUrl;
  }

  private async confirmEmailResend() {
    if (!this.userEmail) {
      this.autoRedirect();

      return;
    }

    if (this.disabledResendEmail) {
      return;
    }

    this.emailResending = true;

    try {
      await API.auth.email.confirmEmailResend({ email: this.userEmail });

      if (this.resendingsPerDay) {
        this.resendingsPerDay.count++;
      } else {
        const timeNextDay = new Date().setDate(new Date().getDate() + 1);

        this.resendingsForAllTime.push({
          expiresAt: new Date(timeNextDay).toISOString(),
          count: 1,
        });
      }

      this.emailResended = true;

      window.clearInterval(this.emailResendedCountdown.intervalId);

      this.emailResendedCountdown.value = emailResendedCountdown;

      this.emailResendedCountdown.intervalId = window.setInterval(() => {
        if (--this.emailResendedCountdown.value > 0) return;

        window.clearInterval(this.emailResendedCountdown.intervalId);
        this.emailResended = false;
      }, 1e3);
    } catch (err) {
      const error = err as AxiosError<ApiErrorResponse>;
      const errorCode = error?.response?.data?.error;

      if (errorCode === ApiError.ACCOUNT_EMAIL_ALREADY_VERIFIED) {
        this.autoRedirect();
      } else {
        this.autoRedirect({ authenticated: false });
      }
    } finally {
      this.emailResending = false;
      this.loading = false;
    }
  }

  private async confirmEmail({ secret }: { secret: string }) {
    try {
      await API.auth.email.confirmEmail({ secret });

      this.autoRedirect();
    } catch (err) {
      const error = err as AxiosError<ApiErrorResponse>;
      const errorCode = error?.response?.data?.error;

      if (errorCode === ApiError.ACCOUNT_EMAIL_ALREADY_VERIFIED) {
        this.autoRedirect();
      } else if (errorCode === ApiError.ACCOUNT_EMAIL_VERIFICATION_EXPIRED) {
        this.confirmEmailResend();
      }
    } finally {
      this.loading = false;
    }
  }

  private created() {
    const { email } = getUserProfileInfo();

    if (!email && !this.secretCode) {
      this.autoRedirect({ authenticated: false });

      return;
    }

    this.userEmail = email;

    try {
      const resendingsForAllTimeFromStorage = localStorage.getItem(
        resendingsForAllTimeStorageKey(this.userEmail)
      );

      const resendingsForAllTime = resendingsForAllTimeFromStorage
        ? (JSON.parse(resendingsForAllTimeFromStorage) as ResendingForAllTime[])
        : [];

      this.resendingsForAllTime = resendingsForAllTime.filter(
        ({ expiresAt }) => {
          const expiresAtDate = new Date(expiresAt);
          const firstDayWeekDate = new Date(
            new Date().setDate(new Date().getDate() - 7)
          );

          return expiresAtDate.getTime() > firstDayWeekDate.getTime();
        }
      );
    } catch {
      this.resendingsForAllTime = [];
    }

    if (this.secretCode) {
      this.confirmEmail({ secret: this.secretCode });
    } else {
      this.loading = false;
    }

    this.$watch(
      () => {
        return this.resendingsForAllTime.reduce<string>(
          (acc, { expiresAt, count }) => {
            return acc + `-${expiresAt}-${count}`;
          },
          ""
        );
      },
      () => {
        if (!this.userEmail) return;

        localStorage.setItem(
          resendingsForAllTimeStorageKey(this.userEmail),
          JSON.stringify(this.resendingsForAllTime)
        );
      }
    );
  }

  private beforeDestroy() {
    window.clearInterval(this.emailResendedCountdown.intervalId);
  }
}
